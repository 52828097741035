<script setup lang="ts">
import { twMerge } from "tailwind-merge";
import { onKeyStroke } from "@vueuse/core";
import { KippieIconButton } from "../";
import { onMounted, watch } from "vue";

const emit = defineEmits(["update:modelValue"]);
interface Props {
	modelValue: boolean;
	placement?: "left" | "right";
	disableEsc?: boolean;
	disableClickAway?: boolean;
	color?: "white" | "yellow";
	disableBodyScroll?: boolean;
}

const {
	modelValue,
	color = "yellow",
	placement = "left",
	disableEsc = false,
	disableClickAway = false,
	disableBodyScroll = true
} = defineProps<Props>();

onMounted(() => {
	if (!disableBodyScroll) return;
	watch(
		() => modelValue,
		(newValue: boolean) => {
			if (newValue) {
				document.body.style.overflow = "hidden";
			} else {
				document.body.style.overflow = "auto";
			}
		}
	);
});

onKeyStroke("Escape", () => {
	if (!disableEsc) emit("update:modelValue", false);
});
</script>

<template>
	<Teleport to="body">
		<Transition
			enter-active-class="duration-300 ease-in-out"
			:enter-from-class="placement === 'left' ? '-translate-x-full' : 'translate-x-full'"
			enter-to-class="translate-0"
			leave-active-class="duration-300 ease-in-out"
			leave-from-class="translate-0"
			:leave-to-class="placement === 'left' ? '-translate-x-full' : 'translate-x-full'"
		>
			<div
				v-if="modelValue"
				class="inset-0 fixed z-30 flex"
				:class="{
					'flex-row-reverse': placement === 'right'
				}"
			>
				<div
					class="w-full md:max-w-sm p-5 h-full flex flex-col justify-between overflow-hidden"
					:class="
						twMerge([
							($attrs.class as string) || '',
							color === 'white' && 'bg-white',
							color === 'yellow' && 'bg-yellow'
						])
					"
				>
					<div class="grow flex flex-col gap-y-10 max-h-full">
						<div
							class="flex items-center justify-between gap-x-6"
							:class="placement === 'left' ? 'flex-row' : 'flex-row-reverse'"
						>
							<KippieIconButton icon="close" @click="$emit('update:modelValue', !modelValue)" />
							<slot name="top" />
						</div>
						<div class="grow max-h-full overflow-y-auto no-scrollbar">
							<slot />
						</div>
						<slot name="bottom" />
					</div>
				</div>
				<div v-if="$slots.right" class="hidden lg:flex flex-col aspect-[1/3] h-full">
					<slot name="right" />
				</div>
				<div
					class="hidden sm:block w-full h-full"
					:class="{ 'cursor-pointer': !disableClickAway }"
					@click="!disableClickAway ? $emit('update:modelValue', !modelValue) : undefined"
				/>
			</div>
		</Transition>
		<Transition
			enter-active-class="duration-300 ease-in-out"
			enter-from-class="opacity-0"
			enter-to-class="opacity-100"
			leave-active-class="duration-300 ease-in-out"
			leave-from-class="opacity-100"
			leave-to-class="opacity-0"
		>
			<div v-if="modelValue" class="bg-black/40 inset-0 fixed hidden sm:block z-20" />
		</Transition>
	</Teleport>
</template>
